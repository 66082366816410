import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBreakpointContext } from './App';
import Foxtrot from './Typography/Foxtrot';
import { getRandomPosts, mediaPrefix } from './api/apiService';
import { formatDate } from './utils/formatDate';
import AppLink from './AppLink';

const styles = {
    headerFont: {
        fontFamily: 'Area',
        fontSize: '1.2em',
        fontWeight: 500,
        color: 'rgba(79, 100, 63, 1)',
        textAlign: 'left',
        width: '100%',
        margin: 0,
        padding: 0,
    },
    categoryAuthorFont: {
        fontFamily: 'Area',
        fontSize: '0.6em',
        fontWeight: 500,
        letterSpacing: '0.15em',
        color: '#777',
        textTransform: 'uppercase',
    },
    categoryAuthorFont: {
        fontFamily: 'Area',
        fontSize: '0.6em',
        fontWeight: 600,
        letterSpacing: '0.15em',
        color: '#777',
        textTransform: 'uppercase',
    },
};

const RelatedArticles = ({ block, currentArticle }) => {
    const [relatedArticles, setRelatedArticles] = useState([]);
    const breakpoint = useBreakpointContext();

    useEffect(() => {
        const fetchRelatedArticles = async () => {
            try {
                const articles = await getRandomPosts(4, currentArticle); // Fetch 5 random articles excluding currentArticle
                setRelatedArticles(articles);
            } catch (error) {
                console.error("Error fetching related articles:", error);
            }
        };

        fetchRelatedArticles();
    }, [currentArticle]);

    return (
        <div
            style={{
                width: '100%',
                backgroundColor: 'rgba(246, 245, 243, 1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div style={{
                maxWidth: '1100px',
                paddingLeft: breakpoint.isMobile ? '20px' : '50px',
                paddingRight: breakpoint.isMobile ? '20px' : '50px',
                paddingTop: breakpoint.isMobile ? '20px' : '40px',
                paddingBottom: breakpoint.isMobile ? '20px' : '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '45px',
            }}>
                <Foxtrot>Continue the conversation</Foxtrot>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: breakpoint.isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
                    gap: '20px',
                }}>

                    {[...relatedArticles].filter(article => article.id !== currentArticle).sort(() => 0.5 - Math.random()).slice(0, 4).map((article, i) => {
                        const { title, category, coverImage, readTime, publicFrom, slug } = article;

                        const coverStyle = {
                            backgroundImage: `url(${mediaPrefix() + coverImage.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '340px',
                            width: '100%',
                            marginBottom: '15px',
                        };

                        return (
                            <AppLink to={`/stories/${slug}`} key={i} style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '20px',
                            }}>
                                <div style={coverStyle} />
                                <div style={styles.categoryAuthorFont}>{category.title}</div>
                                <div style={styles.headerFont}>{title}</div>
                                <div style={styles.categoryAuthorFont}>{formatDate(publicFrom)} | {readTime} MIN READ</div>
                            </AppLink>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RelatedArticles;
