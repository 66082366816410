import React from 'react';
import { Link } from 'react-router-dom';
import { useBreakpointContext } from './App';

const Footer = () => {
    const { isMobile } = useBreakpointContext();

    const footerStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(79, 74, 64, 1)',
        padding: isMobile ? '6em 2em' : '2em', // More vertical padding for mobile
        color: 'white',
        fontFamily: 'Area, Arial, sans-serif',
        fontSize: '0.8em',
        minHeight: '40vh',
        boxSizing: 'border-box',
        flexDirection: isMobile ? 'column' : 'row',
        paddingLeft: isMobile ? '20px' : '100px',
        paddingRight: isMobile ? '20px' : '100px',
        opacity: 0,
        animation: 'fadeIn 1s forwards 0.5s'
    };

    const contentStyles = {
        width: '100%',
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'space-between', // Center content for mobile
        alignItems: 'center',
        gap: '8em',
        flexDirection: isMobile ? 'column' : 'row',
    };

    const columnStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        width: '100%',
        gap: '2.8em',
    };

    const columnStylesRight = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobile ? 'flex-start' : 'flex-end',
        textAlign: 'left',
        width: '100%',
        gap: '2em',
    };

    const linkStyles = {
        color: 'white',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        textAlign: 'center',
        fontFamily: 'AreaNormal',
        fontWeight: 600,
        fontSize: '1.2em',
    };

    const inputStyles = {
        display: 'flex',
        border: '2px solid white',
        borderRadius: '50px',
        overflow: 'hidden',
    };

    const emailInputStyles = {
        flex: '1',
        padding: '0.5em',
        border: 'none',
        backgroundColor: 'transparent',
        color: 'white',
    };

    const submitInputStyles = {
        padding: '0.5em 1em',
        border: 'none',
        backgroundColor: 'white',
        color: 'rgba(79, 74, 64, 1)',
        cursor: 'pointer',
    };

    return (
        <footer style={footerStyles}>
            <div style={contentStyles}>
                <div style={columnStyles}>
                    <Link to="/">
                        <svg width="120" viewBox="0 0 140 18" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 17.3912H2.63936C3.49715 15.6316 4.35494 13.872 5.23473 12.1125C8.13803 12.1125 11.0193 12.1125 13.9226 12.1125L16.54 17.3912H19.2013L11.0193 0.235352H8.18202L0 17.3912ZM6.31247 9.84702C7.54417 7.27364 8.84186 4.5903 9.56768 3.05067H9.63366C10.5794 5.18415 11.7232 7.55957 12.8449 9.84702H6.31247Z" />
                            <path d="M31.056 17.7651C36.8186 17.7651 38.6441 14.246 38.6441 10.9248V0.235352H36.1147V10.7488C36.1147 13.872 34.3552 15.5656 31.0999 15.5656C27.8447 15.5656 26.0852 13.872 26.0852 10.7488V0.235352H23.5558V10.9248C23.5558 14.5759 25.6893 17.7651 31.056 17.7651Z" />
                            <path d="M45.801 17.3912H48.3084C48.3084 15.2137 48.3084 13.0582 48.3084 10.8808H52.2894L57.5901 17.3912H60.4054C58.6239 15.1697 56.7983 12.9483 54.9947 10.7268V10.7048C57.854 10.3529 60.1195 8.63731 60.1195 5.53607C60.1195 1.42306 56.2044 0.235352 53.2352 0.235352H45.801V17.3912ZM48.3084 8.79128C48.3084 6.48184 48.3084 4.48032 48.3084 2.39083H52.7953C55.2807 2.39083 57.4801 3.38059 57.4801 5.60205C57.4801 7.8675 55.4346 8.79128 52.9052 8.79128H48.3084Z" />
                            <path d="M63.9692 17.3912H66.6086C67.4664 15.6316 68.3242 13.872 69.204 12.1125C72.1073 12.1125 74.9886 12.1125 77.8919 12.1125L80.5092 17.3912H83.1706L74.9886 0.235352H72.1513L63.9692 17.3912ZM70.2817 9.84702C71.5134 7.27364 72.8111 4.5903 73.5369 3.05067H73.6029C74.5487 5.18415 75.6924 7.55957 76.8141 9.84702H70.2817Z" />
                            <path d="M88.2395 17.3912H100.82V15.1697C97.4553 15.1697 94.1121 15.1697 90.7469 15.1697V0.235352H88.2395V17.3912Z" />
                            <path d="M108.326 17.3912H110.812V11.2767C113.341 7.60356 115.827 3.90846 118.356 0.235352H115.651C111.274 6.41585 111.494 6.2399 109.602 8.96723H109.558C107.535 6.06394 105.533 3.13865 103.51 0.235352H100.804C102.278 2.28086 105.929 7.77952 108.326 11.2987V17.3912Z" />
                            <path d="M123.504 17.3912H126.011V4.45833H126.055C129.838 8.92324 133.622 13.1242 137.339 17.3912H140V0.235352H137.515V13.3662H137.449C133.71 8.87926 129.641 4.23838 126.165 0.235352H123.504V17.3912Z" />
                        </svg>
                    </Link>
                    <div style={{ fontFamily: 'Area, Arial, sans-serif', maxWidth: '300px', fontSize: '1em', lineHeight: '1.8em', fontWeight: 500 }}>
                        A captivating realm filled with stories of health, self-discovery, and the essence of womanhood.
                        <br /><br />
                        Made in Singapore. 🇸🇬
                    </div>
                    <div style={{
                        fontFamily: 'Area, Arial, sans-serif',
                        fontSize: '0.7em',
                        fontWeight: 600,
                        textTransform: 'uppercase',

                    }}>
                        2023 AURALYN, All Rights Reserved
                    </div>
                </div>
                <div style={columnStylesRight}>
                    <div style={{
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '1.2em',
                    }}>
                        <Link to="/about" style={linkStyles}>About</Link>
                        <Link to="/stories" style={linkStyles}>Stories</Link>
                        <Link to="https://tally.so/r/wgdZvd" style={linkStyles}>Contact</Link>
                        <a href="https://instagram.com/helloauralyn" target="_blank" rel="noopener noreferrer" style={linkStyles}>Instagram</a>
                    </div>
                </div>
                {/*<div style={columnStyles}>
                    <div style={{ fontFamily: 'Area, Arial, sans-serif' }}>Join the Auralyn newsletter</div>
                    <div style={{ fontFamily: 'Area, Arial, sans-serif' }}>Be a part of the Auralyn community and get the latest updates on our intimate conversations.</div>
                    <div style={inputStyles}>
                        <input type="email" placeholder="Enter your email" style={emailInputStyles} />
                        <input type="submit" value="Subscribe" style={submitInputStyles} />
                    </div>
    </div>*/}
            </div>
        </footer>
    );
};

export default Footer;