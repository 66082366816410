import React from 'react';

const Charlie = (props) => {
    return (
        <div style={{
            color: '#4F643F',
            fontFamily: 'AreaNormal',
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '140%',
            borderTop: '1px solid #4F643F',
            paddingTop: '40px',
            marginBottom: '35px',
        }}>
            {props.children}
        </div>
    );
}

export default Charlie;