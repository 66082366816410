import React, { useState } from 'react';

const LoginScreen = ({ onSuccessfulLogin }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch('https://al.hause.host/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            const json = await res.json();

            if (json.token) {
                // Store the token and expiry time in local storage
                localStorage.setItem('jwt_token', json.token);
                localStorage.setItem('exp', json.exp);

                // Notify the parent component of a successful login
                onSuccessfulLogin();
            } else {
                console.error("Authentication failed");
            }
        } catch (error) {
            console.error("Authentication failed:", error);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginScreen;