export const API_CONFIG = {
    BASE_URL: 'https://al.hause.host/api',
    //BASE_URL: 'http://localhost:3000/api',
    HEADERS: {
        'Content-Type': 'application/json',
        //Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InllZWxpbmdAaGF1c2UuY28iLCJpZCI6IjY0ZTIyYWVmY2FkYTE4MDYwN2NjMGVmNiIsImNvbGxlY3Rpb24iOiJ1c2VycyIsInJvbGVzIjpbImFkbWluIl0sImlhdCI6MTY5MzI1OTk5MiwiZXhwIjoxNjkzMjY3MTkyfQ.49rQyGoTewTi2ALRh2ereJdezGensMPyx4YzSoQqwBg',
    },
    ENDPOINTS: {
        POSTS: '/posts',
        PAGES: '/pages',
        AUTHORS: '/authors',
    }
};