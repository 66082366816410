import React from 'react';

const Delta = (props) => {
    return (
        <div style={{
            color: '#4F643F',
            fontFamily: 'AreaNormal',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '200%',
            letterSpacing: '0.32px',
            paddingBottom: '0px',
            marginBottom: '0px',
        }}>
            {props.children}
        </div>
    );
}

export default Delta;