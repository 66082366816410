import React from 'react';
import Button from './Button';
import { useBreakpointContext } from './App';
import { Link } from 'react-router-dom';
import Renderer from './Renderer';
import { mediaPrefix } from './api/apiService';

const Hero = ({ block }) => {
    const imageUrl = mediaPrefix() + block?.backgroundImage?.url;  // Adjusted path for image URL
    const breakpoint = useBreakpointContext();

    const mobileStyles = {
        headerFontSize: breakpoint.isMobile ? '2.5em' : '4.3em',
        textFontSize: breakpoint.isMobile ? '1.4em' : '2em',
        marginLeft: breakpoint.isMobile ? '10px' : '70px',
        padding: breakpoint.isMobile ? '20px' : '0',
        maxWidth: breakpoint.isMobile ? '90%' : '40%',
    };

    const divBackground = {
        justifyContent: 'center',
        textAlign: 'left',
        alignItems: 'flex-start',
    };

    const textStyles = {
        justifyContent: 'flex-start',
        textAlign: 'left',
        alignItems: 'flex-start',
    };

    const headerFont = {
        fontFamily: 'Area',
        lineHeight: '1.1em',
        color: 'white',
    };

    const textFont = {
        fontFamily: 'AreaNormal',
        color: 'white',
    };

    return (
        <div
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                minHeight: '800px',
            }}
        >
            <div style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                ...divBackground,
            }}>
                <div style={{ ...mobileStyles, display: 'flex', flexDirection: 'column', height: 'auto', gap: '50px', ...textStyles }}>
                    <div style={{
                        color: 'white', fontSize: mobileStyles.headerFontSize, fontWeight: 500, whiteSpace: 'normal', ...headerFont
                    }}>
                        {block?.title}
                    </div>
                    <div style={{ fontSize: mobileStyles.textFontSize, ...textFont }}>
                        <Renderer content={block?.richText} />
                    </div>
                    {block?.links?.map((linkItem, index) => {
                        const destination = linkItem?.link?.url;
                        return (
                            <Link key={index} to={destination} style={{ textDecoration: 'none' }}>
                                <Button text={linkItem?.link.label} bgColor="transparent" textColor="#ffffff" />
                            </Link>
                        );
                    })}
                </div>
                <div style={{ height: '20px' }} />
            </div>
        </div>
    );
};

export default Hero;
