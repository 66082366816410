import React from 'react';
import { Link } from 'react-router-dom';
import { useBreakpointContext } from './App';
import Alpha from './Typography/Alpha';
import Bravo from './Typography/Bravo';
import Button from './Button';

const StoryToShare = () => {
    const { isMobile } = useBreakpointContext();

    const shareStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#CDC6B6',
        padding: isMobile ? '6em 2em' : '6.5em', // More vertical padding for mobile
        color: 'white',
        fontFamily: 'Area, Arial, sans-serif',
        fontSize: '0.8em',
        boxSizing: 'border-box',
        flexDirection: isMobile ? 'column' : 'row',
    };

    const contentStyles = {
        maxWidth: '1200px',
        width: '100%',
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'center', // Center content for mobile
        alignItems: 'start',
        gap: '2.5em',
        flexDirection: isMobile ? 'column' : 'column',
    };

    const columnStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: '2em',
    };

    const linkStyles = {
        color: 'white',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        textAlign: 'center',
    };

    const inputStyles = {
        display: 'flex',
        border: '2px solid white',
        borderRadius: '50px',
        overflow: 'hidden',
    };

    const emailInputStyles = {
        flex: '1',
        padding: '0.5em',
        border: 'none',
        backgroundColor: 'transparent',
        color: 'white',
    };

    const submitInputStyles = {
        padding: '0.5em 1em',
        border: 'none',
        backgroundColor: 'white',
        color: 'rgba(79, 74, 64, 1)',
        cursor: 'pointer',
    };


    return (
        <div style={shareStyles}>
            <div style={{
                maxWidth: '1100px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: '3.5em',
                flexDirection: isMobile ? 'column' : 'row',
            }}>
                <div style={contentStyles}>
                    <Alpha>Have a story to share?</Alpha>
                    <Bravo>Whether you want to share your story to help others, connect with other women who may be going through similar situations, or simply find comfort in knowing that you're not alone, we are here to listen.</Bravo>
                </div>
                <Link to="https://tally.so/r/mZENpy" style={linkStyles}>
                    <Button text="Share your story with us" style="darkLava" />
                </Link>
            </div>
        </div>
    );
};

export default StoryToShare;