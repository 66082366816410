import React from 'react';

const Quote = (props) => {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
            marginBottom: '40px',
        }}>
            <blockquote style={{
                color: '#4F643F',
                textAlign: 'center',
                fontFamily: 'Area',
                fontSize: '24px',
                fontStyle: 'italic',
                fontWeight: 500,
                lineHeight: '160%',
                letterSpacing: '0.24px',
                maxWidth: '60%'
            }}>
                {props.children}
            </blockquote>
        </div>
    );
}

export default Quote;