import React from 'react';
import { useBreakpointContext } from '../App';
import { Link } from 'react-router-dom';
import Renderer from '../Renderer';
import { mediaPrefix } from '../api/apiService';
import Button from '../Button';

const Promotion = ({ block }) => {
    const imageUrl = mediaPrefix() + block?.backgroundImage?.url;  // Adjusted path for image URL
    const breakpoint = useBreakpointContext();

    const mobileStyles = {
        headerFontSize: breakpoint.isMobile ? '2em' : '4em',
        textFontSize: breakpoint.isMobile ? '1em' : '1.2em',
        marginLeft: breakpoint.isMobile ? '0px' : '0',
        paddingLeft: breakpoint.isMobile ? '10px' : '40px',
        paddingRight: breakpoint.isMobile ? '10px' : '40px',
        paddingTop: breakpoint.isMobile ? '80px' : '150px',
        paddingBottom: breakpoint.isMobile ? '80px' : '150px',
        maxWidth: breakpoint.isMobile ? '90%' : '50%',
    };
    const divBackground = {
        backgroundColor: '#D7DECF',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    };
    const textStyles = {
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        lineHeight: '2.5em',
    };
    const headerFont = {
        fontFamily: 'Area',
        lineHeight: '1.1em',
        color: '#4F643F',
        fontSize: '2.6em',
    };
    const textFont = {
        fontFamily: 'AreaNormal',
        color: '#4F643F',
    };

    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            ...divBackground,
        }}>
            <div style={{ ...mobileStyles, display: 'flex', flexDirection: 'column', height: 'auto', gap: '50px', ...textStyles }}>
                <img src={mediaPrefix() + block?.titleIcon?.url} style={{
                    width: '75px',
                }} />
                <div style={{
                    color: 'white', fontSize: mobileStyles.headerFontSize, fontWeight: 500, whiteSpace: 'normal', ...headerFont
                }}>
                    {block?.title}
                </div>
                <div style={{ fontSize: mobileStyles.textFontSize, ...textFont }}>
                    <Renderer content={block?.richText} />
                </div>
                {block?.link && (
                    <Link to={block?.link.url} style={{ textDecoration: 'none' }}>
                        <Button text={block?.link.label} bgColor="#4F643F" textColor="#ffffff" border={false} />
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Promotion;
