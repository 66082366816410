import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Hero from './Hero';
import FeaturedArticles from './FeaturedArticles';
import About from './About';
import { SquareLoader } from 'react-spinners';
import { fetchPage, mediaPrefix } from './api/apiService';  // Ensure the correct import path
import Footer from './Footer';
import Promotion from './blocks/Promotion';
import { Helmet } from 'react-helmet';

const buildPage = (layout) => {
    if (!layout) {
        return null;
    }
    return layout.map((block) => buildSection(block));
};

const buildSection = (block) => {
    switch (block.blockType) {
        case 'cover':
            return <Hero block={block} />;
        case 'storygallery':
            return <FeaturedArticles settings={block} />;
        case 'promotion':
            return <Promotion block={block} />;
        // Add more cases as needed for other block types
        default:
            return null;
    }
};

const Page = () => {
    let { slug } = useParams();
    const [incoming, setIncoming] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isPreview } = useState(false);

    useEffect(() => {
        setIncoming(null);
        setLoading(true);
        setError(null);

        if (!slug) {
            slug = 'home';
        }
        const fetchData = async () => {
            try {
                const pageData = await fetchPage(slug, isPreview);  // Fetch the specific page based on slug
                setIncoming(pageData);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [slug]);

    if (loading) return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgb(79, 74, 64)'
        }}>
            <SquareLoader
                color="#CDC6B6"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
    if (error) return <div>Error: {error}</div>;

    if (!loading && incoming) {
        const metaImage = mediaPrefix() + incoming?.meta?.image?.url;
        return (
            <>
                <Helmet>
                    <title>{incoming?.meta?.title}</title>
                    <meta name="description" content={incoming?.meta?.description} />
                    {/* Add other meta tags as needed */}
                    <meta property="og:title" content={incoming?.meta?.title} />
                    <meta property="og:description" content={incoming?.meta?.description} />
                    <meta property="og:image" content={metaImage} />
                    {/* Add more open-graph or other social tags as needed */}
                </Helmet>
                <div className="flex flex-col items-center h-auto" style={{ opacity: 0, animation: 'fadeIn 1s forwards 0.5s' }}>
                    {
                        slug === "about" ?
                            <About />
                            :
                            buildPage(incoming.layout)
                    }
                </div>
                <Footer />
            </>
        );
    }

    return null;
};

export default Page;
