import React from 'react';

const Golf = (props) => {
    return (
        <div style={{
            color: '#4F643F',
            fontFamily: 'AreaNormal',
            fontSize: '19px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '180%', // 36px
            marginBottom: '40px',
        }}>
            {props.children}
        </div>
    );
}

export default Golf;