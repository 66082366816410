import { API_CONFIG } from './apiConfig';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

export const mediaPrefix = () => {
    return 'https://hausebucket-1.fra1.cdn.digitaloceanspaces.com/auralyn';
};

const checkPreviewFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('preview') === 'true';
};

export const usePreviewAuthentication = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [jwt, setJwt] = useState(localStorage.getItem('jwt_token'));
    const [isPreview, setIsPreview] = useState(checkPreviewFromUrl());

    useEffect(() => {
        if (jwt) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [jwt]);

    useEffect(() => {
        const storedJwt = localStorage.getItem('jwt_token');
        setJwt(storedJwt);
    }, []);

    return { isAuthenticated, jwt, isPreview };
};


export const fetchPosts = async () => {
    const isPreview = checkPreviewFromUrl();
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: isPreview ? `JWT ${localStorage.getItem('jwt_token')}` : null,
        },
    };

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.POSTS}${draftQuery}&limit=100&sort=-publicFrom`, fetchOptions);
        const data = await response.json();
        console.log(data.docs);
        console.log("STORIES");
        return data.docs;
    } catch (error) {
        console.error("Error fetching articles:", error);
        throw error;
    }
};

export const getRandomPosts = async (max = 100, exclude) => {
    const isPreview = checkPreviewFromUrl();
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';
    const fetchOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: isPreview ? `JWT ${localStorage.getItem('jwt_token')}` : null,
        },
    };

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.POSTS}${draftQuery}`, fetchOptions);
        const data = await response.json();

        // Filter out the post to exclude
        const filteredData = data.docs.filter(post => post.id !== exclude);

        // Randomize the array
        const shuffled = filteredData.sort(() => 0.5 - Math.random());

        // Get 'max' items
        const randomPosts = shuffled.slice(0, max);

        return randomPosts;
    } catch (error) {
        console.error("Error fetching random posts:", error);
        throw error;
    }
};


export const fetchPost = async (slug) => {
    const isPreview = checkPreviewFromUrl();

    let query = `?where[slug][equals]=${slug}&locale=en`;

    if (isPreview) {
        query += '&draft=true';
    } else {
        query += '&draft=false';
    }

    const fetchOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: isPreview ? `JWT ${localStorage.getItem('jwt_token')}` : null,
            //Authorization: isPreview ? '64e0e9015d7596620e9424d6 API-Key ab506a74-db3b-436e-ac38-b7ab1a9b23b1' : null,
        },
    };

    // Add credentials only if isPreview is true
    /*if (isPreview) {
        fetchOptions.credentials = 'include';
    }*/

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.POSTS}${query}`, fetchOptions);

        const data = await response.json();
        console.log(data);
        console.log("POST BY SLUG");

        return data.docs[0];
    } catch (error) {
        console.error(`Error fetching post with slug ${slug}:`, error);
        throw error;
    }
};

export const fetchAuthor = async (uuid) => {
    const isPreview = checkPreviewFromUrl();
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';

    let fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}/authors/${uuid}${draftQuery}`, fetchOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching author:", error);
        throw error;
    }
};

export const fetchPages = async () => {
    const isPreview = checkPreviewFromUrl();
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PAGES}${draftQuery}`, fetchOptions);
        const data = await response.json();
        console.log(data.docs);
        return data.docs;
    } catch (error) {
        console.error("Error fetching pages:", error);
        throw error;
    }
};

export const fetchPage = async (slug) => {
    const isPreview = checkPreviewFromUrl();
    const draftQuery = isPreview ? '&locale=en&draft=true' : '&locale=en&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: isPreview ? `JWT ${localStorage.getItem('jwt_token')}` : null,
        },
    };

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PAGES}?where[slug][equals]=${slug}${draftQuery}`, fetchOptions);
        const data = await response.json();

        // Assuming the response returns an array, we'll return the first item for a specific slug
        console.log(data.docs[0]);
        return data.docs[0];
    } catch (error) {
        console.error(`Error fetching page with slug ${slug}:`, error);
        throw error;
    }
};