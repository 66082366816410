import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { useBreakpointContext } from './App';
import { DiscussionEmbed } from 'disqus-react';
import StoryToShare from './StoryToShare';
import Charlie from './Typography/Charlie';
import Delta from './Typography/Delta';
import Echo from './Typography/Echo';
import Quote from './Quote';
import RelatedArticles from './RelatedArticles';
import { SquareLoader } from 'react-spinners';
import Golf from './Typography/Golf';
import { fetchPost, fetchAuthor, mediaPrefix } from './api/apiService'; // Make sure to adjust the path accordingly
import { formatDate } from './utils/formatDate';
import ArticleRenderer from './ArticleRenderer';
import { PreviewContext } from './App';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { usePreviewAuthentication } from './api/apiService';


const Article = () => {
    const { slug } = useParams();
    const [article, setArticle] = useState(null);
    const [author, setAuthor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [relatedArticles, setRelatedArticles] = useState([]);

    const location = useLocation();
    const { isPreview, isAuthenticated, jwt } = usePreviewAuthentication(location);

    const breakpoint = useBreakpointContext();

    const introWithBreaks = article?.introduction?.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br />
        </>
    );

    const styles = {
        outerContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgba(246, 245, 243, 1)',
            paddingTop: breakpoint.isMobile ? '0px' : '50px',
        },
        container: {
            fontFamily: 'AreaNormal, sans-serif',
            margin: '0 auto',
            padding: 0,
            maxWidth: '1100px',
            lineHeight: '1.5',
            color: '#333',
            paddingTop: breakpoint.isMobile ? 0 : '120px',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
        },
        headerSection: {
            display: 'flex',
            flexDirection: breakpoint.isMobile ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '40px',
            //width: breakpoint.isMobile ? '100%' : '120%',
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingLeft: breakpoint.isMobile ? '20px' : '0px',
            paddingRight: breakpoint.isMobile ? '20px' : '0px',
        },
        headerFont: {
            fontFamily: 'Area, sans-serif',
            fontSize: '2.5em',
            fontWeight: 500,
            marginBottom: '10px',
            color: '#4F643F',
            lineHeight: '120%',
        },
        categoryAuthorFont: {
            fontFamily: 'Area',
            fontSize: '0.6em',
            fontWeight: 600,
            letterSpacing: '0.15em',
            color: '#777',
            textTransform: 'uppercase',
        },
        textFont: {
            fontSize: '0.92em',
            lineHeight: '2',
            margin: '40px 0',
            paddingLeft: breakpoint.isMobile ? '20px' : '50px',
            paddingRight: breakpoint.isMobile ? '20px' : '50px',
        },
        image: {
            width: breakpoint.isMobile ? '100%' : '60%',
            height: breakpoint.isMobile ? '70vh' : '700px',
            objectFit: 'cover',
            marginBottom: '40px',
        },
        markdownImage: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            marginBottom: '20px',
        }
    };

    useEffect(() => {
        setArticle(null);
        setRelatedArticles([]);
        setLoading(true);
        setError(null);

        const fetchData = async () => {
            try {
                const articleData = await fetchPost(slug, isPreview);
                console.log("Article Data");
                console.log(articleData);
                setArticle(articleData);
                // TODO: Adjust the related articles fetching logic if needed. I kept it as is since it's not clear how it should be updated.
                setLoading(false);
                console.log("PREVIEW!!!!");
                console.log(isPreview);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [slug]);

    useEffect(() => {
        console.log("HER");
        if (article) {
            const fetchAuthorData = async () => {
                try {
                    const authorData = await fetchAuthor(article.author.id);
                    console.log("Author Data");
                    console.log(authorData);
                    setAuthor(authorData);
                } catch (err) {
                    setError(err.message);
                }
            };
            fetchAuthorData();
        }
    }, [article]);


    if (loading) return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgb(79, 74, 64)'
        }}>
            <SquareLoader
                color="#CDC6B6"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
    if (error) return <div>Error: {error}</div>;

    if (!loading && article && author) {
        const metaImage = mediaPrefix() + article?.meta?.image?.url;

        return (
            <>
                <Helmet>
                    <title>{article?.meta?.title}</title>
                    <meta name="description" content={article?.meta?.description} />
                    {/* Add other meta tags as needed */}
                    <meta property="og:title" content={article?.meta?.title} />
                    <meta property="og:description" content={article?.meta?.description} />
                    <meta property="og:image" content={metaImage} />
                    {/* Add more open-graph or other social tags as needed */}
                </Helmet>
                <div style={{ opacity: 0, animation: 'fadeIn 1s forwards 0.5s' }}>
                    <div style={styles.outerContainer}>
                        <div style={styles.container}>
                            <div style={styles.headerSection}>
                                <div style={styles.titleSection}>
                                    <p style={styles.categoryAuthorFont}>{article.category.title}</p>
                                    <div style={styles.headerFont}>{article.title}</div>
                                    <p style={styles.categoryAuthorFont}>by {author.name} | {formatDate(article.publicFrom)} | {article.readTime} minutes read</p>
                                </div>
                                <img src={mediaPrefix() + article.coverImage.url} alt={article.coverImage.alt} style={styles.image} />
                            </div>
                            <div style={styles.textFont}>
                                <Golf>{introWithBreaks}</Golf>
                                <ArticleRenderer content={article?.content} />
                            </div>
                            <div style={{
                                paddingTop: '30px',
                                paddingBottom: '60px',
                                paddingLeft: breakpoint.isMobile ? '20px' : '0px',
                                paddingRight: breakpoint.isMobile ? '20px' : '0px',
                            }}>
                                <DiscussionEmbed
                                    shortname='auralyn'
                                    config={
                                        {
                                            url: 'https://www.auralyn.com/articles/' + article?.slug,
                                            identifier: article.slug,
                                            title: article.title,
                                            language: 'en_US' //e.g. for Traditional Chinese (Taiwan)	
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <RelatedArticles currentArticle={article?.id} />
                    <StoryToShare />
                </div>
                <Footer />
            </>
        );
    }

    return null;
};

export default Article;
