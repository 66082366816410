import { differenceInDays, format, isToday, isYesterday } from "date-fns";

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();

    const dayDifference = differenceInDays(today, date);

    if (isToday(date)) {
        return 'Today';
    } else if (isYesterday(date)) {
        return 'Yesterday';
    } else if (dayDifference < 7) {
        return `${dayDifference} days ago`;
    } else {
        return format(date, 'dd. MMMM yyyy');
    }
};