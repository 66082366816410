import React from 'react';

const Echo = (props) => {
    return (
        <div style={{
            color: '#212121',
            fontFamily: 'AreaNormal',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '200%',
            letterSpacing: '0.32px',
            paddingBottom: '0px',
            marginBottom: '30px',
        }}>
            {props.children}
        </div>
    );
}

export default Echo;