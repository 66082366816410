import React, { Fragment } from "react";
import escapeHTML from "escape-html";
import { Text } from "slate";
import Charlie from './Typography/Charlie';
import Delta from './Typography/Delta';
import Echo from './Typography/Echo';
import Hotel from './Typography/Hotel';
import Quote from './Quote';
import { mediaPrefix } from "./api/apiService";
import Renderer from "./Renderer";

const ArticleRenderer = ({ content }) => {
    const styles = {
        markdownImage: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            marginBottom: '0px',
        },
    };

    const serialize = (children) => {
        return children?.map((node, i) => {
            if (Text.isText(node)) {
                let text = (
                    <span dangerouslySetInnerHTML={{ __html: escapeHTML(node?.text) }} />
                );

                if (node?.bold) {
                    text = <strong key={i}>{text}</strong>;
                }

                if (node?.code) {
                    text = <code key={i}>{text}</code>;
                }

                if (node?.italic) {
                    text = <em key={i}>{text}</em>;
                }

                // Handle other leaf types here...

                return <Fragment key={i}>{text}</Fragment>;
            }

            if (!node) {
                return null;
            }

            switch (node?.type) {
                case "h1":
                    return <Charlie key={i}>{serialize(node?.children)}</Charlie>;
                case "h2":
                    return <Delta key={i}>{serialize(node?.children)}</Delta>;
                case "h3":
                    return <Hotel key={i}>{serialize(node?.children)}</Hotel>;
                case "blockquote":
                    return <Quote key={i}>{serialize(node?.children)}</Quote>;
                case "ul":
                    return <ul key={i}>{serialize(node?.children)}</ul>;
                case "ol":
                    return <ol key={i}>{serialize(node?.children)}</ol>;
                case "li":
                    return <li key={i}>{serialize(node?.children)}</li>;
                case "link":
                    return (
                        <a href={escapeHTML(node?.url)} key={i}>
                            {serialize(node?.children)}
                        </a>
                    );
                case "upload":
                    return (
                        <div style={{
                            width: '100%',
                            marginTop: '50px',
                            marginBottom: '50px',
                        }}>
                            <img src={mediaPrefix() + escapeHTML(node?.value?.url)} alt={node?.value?.alt} style={styles.markdownImage} key={i} />
                            {node?.value?.caption && (
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Renderer content={node?.value?.caption} />
                                </div>)}
                        </div>
                    );
                default:
                    return <Echo key={i}>{serialize(node?.children)}</Echo>;
            }
        });
    };

    return <>{content ? serialize(content) : null}</>;
};

export default ArticleRenderer;