import React from 'react';
import { Link } from 'react-router-dom';
import Alpha from './Typography/Alpha';
import Bravo from './Typography/Bravo';
import Button from './Button';
import backgroundImage from './about-background.jpeg';
import yeeling from './yeeling.jpg';
import martina from './martina.jpg';
import vanessaProfile from './vanessa-profile.jpg';
import StoryToShare from './StoryToShare';
import { useBreakpointContext } from './App';
import Footer from './Footer';

const About = () => {
    const breakpoint = useBreakpointContext();
    return (
        <div>
            <div style={{
                width: '100%',
                height: '65vh',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                }}>
                    <div style={{
                        fontSize: breakpoint.isMobile ? '1.5em' : '2.4em',
                        fontFamily: 'AreaNormal',
                        color: '#4F643F',
                        maxWidth: '1000px',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                    }}>
                        We celebrate the resilience and wisdom of womanhood, helping women to make informed decisions about their well-being.
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%',
                backgroundColor: '#4F643F',
                paddingTop: breakpoint.isMobile ? '60px' : '120px',
                paddingBottom: breakpoint.isMobile ? '60px' : '120px',

            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '30px',

                }}>
                    <div style={{
                        fontSize: breakpoint.isMobile ? '1.5em' : '2.6em',
                        fontFamily: 'Area',
                        color: '#ffffff',
                        maxWidth: '1000px',
                    }}>
                        Our Story
                    </div>
                    <div style={{
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        fontFamily: 'AreaNormal',
                        color: '#ffffff',
                        maxWidth: '1000px',
                        lineHeight: '2',
                    }}>
                        Auralyn was inspired by our founder, Yeeling’s personal journey into the complex world of women's health. Confronted with health challenges in 2022, Yeeling discovered a need for more open conversation and understanding, especially within the context of Asian discourse.
                        <br /><br />
                        Auralyn was created as a response, a place where women can gather to share experiences and find companionship in their unique health journeys. With an emphasis on empathy, connection, and education, Yeeling and her team are dedicated to empowering women to make informed decisions about their bodies.
                        <br /><br />
                        By offering a safe space for discussion, and illuminating often overlooked truths, Auralyn strives to foster a community that supports women in feeling acknowledged and in control of their health.
                        <br /><br />
                        Our goal is to create a world where women's healthcare is understood holistically, and every woman feels confident and connected to her body.
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%',
                backgroundColor: '#F6F5F3',
                paddingTop: breakpoint.isMobile ? '0px' : '80px',
                paddingBottom: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '30px',
                    maxWidth: '1000px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '100px',
                }}>
                    <div style={{
                        fontSize: breakpoint.isMobile ? '1.5em' : '2.6em',
                        fontFamily: 'Area',
                        color: '#4F643F',
                        maxWidth: '1000px',
                        marginBottom: '30px',
                    }}>
                        Founder
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: breakpoint.isMobile ? 'column' : 'row',
                        justifyContent: 'center',
                        gap: '30px',
                        borderBottom: '1px solid #4F643F',
                        paddingBottom: '60px',
                    }}>
                        <div style={{
                            width: breakpoint.isMobile ? 'auto' : '50%',
                            backgroundImage: `url(${yeeling})`,
                            backgroundSize: 'cover',
                            height: breakpoint.isMobile ? '680px' : 'auto',
                        }}></div>
                        <div style={{
                            paddingLeft: breakpoint.isMobile ? '0px' : '30px',
                            paddingRight: '30px',
                            fontFamily: 'AreaNormal',
                            color: '#4F4A40',
                            maxWidth: '1000px',
                            lineHeight: '2',
                            textAlign: 'left',
                            width: breakpoint.isMobile ? 'auto' : '50%',
                        }}>
                            <div style={{
                                fontSize: '2rem',
                                fontFamily: 'AreaNormal',
                                color: '#212121',
                                maxWidth: '1000px',
                                marginBottom: '20px',
                            }}>
                                Yeeling Chua
                            </div>
                            Inspired by her personal health experiences, pairing with her background in UX design and education, Yeeling created Auralyn in hopes to make complex health information more relatable and accessible.
                            <br /><br />
                            Outside of Auralyn, Yeeling spends her time managing Hause Collective - her digital product design agency and training hub. Additionally, she teaches at several schools and institutions, including Singapore Polytechnic and Orita Sinclair School of Music & Design.
                            <br /><br />
                            Beyond her professional engagements, Yeeling's fondness for exploration has shaped her into an independent and resilient individual. Guided by her motto: "Don't navigate life by fear. Instead, explore the possibilities and find courage in exploration", Yeeling’s love for food and her prior journey as a digital nomad has not only broadened her horizons but also honed her ability to navigate unexpected situations.
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%',
                backgroundColor: '#F6F5F3',
                paddingTop: '60px',
                paddingBottom: '80px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',

            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '30px',
                    maxWidth: '1000px',
                    padding: '20px'
                }}>
                    <div style={{
                        fontSize: breakpoint.isMobile ? '1.5em' : '2.6em',
                        fontFamily: 'Area',
                        color: '#4F643F',
                        maxWidth: '1000px',
                        marginBottom: '30px',
                    }}>
                        Meet the team
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: breakpoint.isMobile ? 'column' : 'row',
                        justifyContent: 'center',
                        gap: '30px',
                        paddingBottom: '60px',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            flexDirection: 'column',
                            gap: '30px',
                        }}>
                            <div style={{
                                height: '680px',
                                backgroundImage: `url(${martina})`,
                                backgroundSize: 'cover',

                            }}></div>
                            <div style={{
                                fontFamily: 'AreaNormal',
                                color: '#4F4A40',
                                maxWidth: '1000px',
                                lineHeight: '2',
                                textAlign: 'left',
                            }}>
                                <div style={{
                                    fontSize: '2rem',
                                    fontFamily: 'AreaNormal',
                                    color: '#212121',
                                    maxWidth: '1000px',
                                    marginBottom: '20px',
                                }}>
                                    Martina Maitan
                                </div>
                                Martina, originally from the enchanting city of Venice, left her island to embrace another island – Singapore – over a decade ago. Through her experiences in this vibrant region, she's become acutely aware that many conversations about women's health are often confined to small, close-knit communities rather than taking center stage in daily discourse.
                                <br /><br />
                                She's driven by the simple yet powerful goal of uncovering taboos and sharing stories that can genuinely help people. This, coupled with her UX design expertise, has propelled her to contribute to creating a platform for positive change through relatable narratives.
                                <br /><br />
                                Martina seeks to find beauty in the ordinary; pleasant aesthetics are everywhere in our daily life. She loves sharing this sentiment with her 3-year-old daughter, guiding her toward self-awareness and independence. They both enjoy visiting museums, exploring architecture, and experiencing well-designed public spaces that enhance the city living experience.
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            flexDirection: 'column',
                            gap: '30px',
                        }}>
                            <div style={{
                                height: '680px',
                                backgroundImage: `url(${vanessaProfile})`,
                                backgroundSize: 'cover',

                            }}></div>
                            <div style={{
                                fontFamily: 'AreaNormal',
                                color: '#4F4A40',
                                maxWidth: '1000px',
                                lineHeight: '2',
                                textAlign: 'left',
                            }}>
                                <div style={{
                                    fontSize: '2rem',
                                    fontFamily: 'AreaNormal',
                                    color: '#212121',
                                    maxWidth: '1000px',
                                    marginBottom: '20px',
                                }}>
                                    Vanessa Huang
                                </div>
                                Meet Vanessa, a Content Creator and Marketing Coordinator with a passion for empowering women in Asia through the transformative platform, Auralyn. With her skills, Vanessa is dedicated to introducing Auralyn to a wide audience, believing it can provide women with the knowledge and means to better understand themselves while offering a space to share their stories.
                                <br /><br />
                                Beyond her involvement with Auralyn, Vanessa is a dynamic Marketing Executive and Social Media Coordinator, collaborating with various start-ups on a global scale. Her expertise in crafting engaging content and her keen eye for marketing strategies have earned her a reputation as an independent force in the industry.
                                <br /><br />
                                Venturing beyond her professional roles, Vanessa is also a travel vlogger, captivating audiences on TikTok and Instagram with her journey exploring Asia. Her travel experiences have become a big part of her lifestyle, merging seamlessly with her content creation endeavors.
                                <br /><br />
                                With an unwavering love for travel and a creative drive, Vanessa's journey is a reflection to her commitment to both personal growth and inspiring others to explore the world and express themselves authentically.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StoryToShare />
            <Footer />
        </div>
    );
};

export default About;