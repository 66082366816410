import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useBreakpointContext } from './App';
import { fetchPosts } from './api/apiService';
import { formatDate } from './utils/formatDate';
import AppLink from './AppLink';

const styles = {
    headerFont: {
        fontFamily: 'Area',
        fontSize: '1.6em',
        lineHeight: '140%',
        color: 'rgba(79, 100, 63, 1)',
        textAlign: 'left',
        width: '100%',
        fontWeight: 500,
    },
    textFont: {
        fontFamily: 'AreaNormal',
    },
    categoryAuthorFont: {
        fontFamily: 'AreaNormal',
        fontSize: '0.6em',
        fontWeight: 500,
        letterSpacing: '0.15em',
        color: '#777',
        textTransform: 'uppercase',
        fontWeight: 700,
    },
};

const FeaturedArticles = ({ settings }) => {
    const breakpoint = useBreakpointContext();
    const [animatedScales, setAnimatedScales] = useState([]);
    const [visibleIndices, setVisibleIndices] = useState([]);
    const articleRefs = useRef([]);
    const { isPreview } = useState(false);

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const getArticles = async () => {
            try {
                const articlesData = await fetchPosts(isPreview);
                setArticles(articlesData);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        getArticles();
    }, [isPreview]);



    const checkVisibility = () => {
        articleRefs.current.forEach((ref, index) => {
            if (ref) {
                const rect = ref.getBoundingClientRect();
                const offset = 200; // Adjust this value for a greater or lesser offset
                if (rect.top <= window.innerHeight - offset && rect.bottom >= 0) {
                    if (!visibleIndices.includes(index)) {
                        setVisibleIndices(prevIndices => [...prevIndices, index]);
                    }
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkVisibility);
        checkVisibility();
        return () => {
            window.removeEventListener('scroll', checkVisibility);
        };
    }, []);

    const generateRandomScale = () => {
        return (Math.random() * 1.7) + 0.85;
    };

    const articleStyle = (isMobile, scale, index) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: isMobile ? '100%' : `${scale * 270}px`,
        height: 'auto',
        textAlign: 'right',
        //minHeight: '550px',
        textDecoration: 'none',
        textAlign: 'left',
        border: '1px solid transparent',
        transition: 'all 0.3s ease-in-out',
        gridColumnEnd: isMobile ? 'span 1' : `span ${Math.ceil(scale)}`,
        gridRowEnd: isMobile ? 'span 1' : `span ${Math.ceil(scale)}`,
        opacity: visibleIndices.includes(index) ? 1 : 0,
        transform: visibleIndices.includes(index) ? 'translateY(0)' : 'translateY(20px)',
        transition: `opacity 0.5s ${index * 0.25}s, transform 0.5s ${index * 0.25}s`,
    });

    useEffect(() => {
        const scales = articles.map(() => generateRandomScale());
        scales[0] = 1;
        scales[scales.length - 1] = 1;
        setAnimatedScales(scales);
    }, [articles.length]);

    checkVisibility();

    return (
        <div
            style={{
                backgroundColor: 'rgba(246, 245, 243, 1)',
                display: 'grid',
                gridTemplateColumns: breakpoint.isMobile ? '1fr' : 'repeat(auto-fit, minmax(240px, 1fr))',
                gridRowGap: '60px',
                gridColumnGap: '100px',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: breakpoint.isMobile ? '20px' : '100px',
                paddingRight: breakpoint.isMobile ? '20px' : '100px',
                paddingTop: breakpoint.isMobile ? '100px' : '120px',
                paddingBottom: breakpoint.isMobile ? '50px' : '120px',
            }}
        >
            {/*<div style={{ color: 'black', ...styles.headerFont, marginBottom: '40px', fontSize: '2.5em', display: 'none' }}>
                {settings?.title}
        </div>*/}

            {articles.map((article, i) => {
                const { title, coverImage, publicFrom, readTime, category, slug, id } = article;

                const coverURL = "https://hausebucket-1.fra1.cdn.digitaloceanspaces.com/auralyn" + coverImage.url;
                //console.log(coverURL);

                const coverStyle = {
                    backgroundImage: `url(${coverURL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: breakpoint.isMobile ? '700px' : `${animatedScales[i] * 350}px`,
                    width: '100%',
                    transition: 'all 0.5s ease-in-out',
                };

                return (
                    <AppLink
                        ref={el => articleRefs.current[i] = el}
                        to={`/stories/${slug}`}
                        key={i}
                        style={articleStyle(breakpoint.isMobile, animatedScales[i], visibleIndices.includes(i) ? i : -1)}
                        onMouseOver={e => {
                            if (!breakpoint.isMobile) {
                                e.currentTarget.style.transform = 'scale(1.03)';
                                e.currentTarget.firstChild.style.backgroundSize = 'cover';
                            }
                        }}
                        onMouseOut={e => {
                            if (!breakpoint.isMobile) {
                                e.currentTarget.style.transform = 'scale(1)';
                                e.currentTarget.firstChild.style.backgroundSize = 'cover';
                            }
                        }}
                    >
                        <div style={coverStyle} />

                        <div style={{
                            marginTop: '25px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            gap: '15px',
                            width: '100%',
                        }}>
                            <div style={styles.categoryAuthorFont}>{category.title}</div>
                            <div style={{ color: 'black', ...styles.headerFont }}>
                                {title}
                            </div>
                            <div style={styles.categoryAuthorFont}>{formatDate(publicFrom)} | {readTime} MIN READ</div>
                        </div>

                    </AppLink>
                );
            })}
        </div>
    );
};

export default FeaturedArticles;