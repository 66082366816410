import React from 'react';

const Bravo = (props) => {
    return (
        <div style={{
            color: '#4F4A40',
            fontFamily: 'AreaNormal',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '180%',
            letterSpacing: '0.32px',
            textAlign: 'left',
        }}>
            {props.children}
        </div>
    );
}

export default Bravo;
