import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { usePreviewAuthentication } from './api/apiService';

const AppLink = forwardRef(({ to, children, ...props }, ref) => {

    const { isPreview } = usePreviewAuthentication();

    // If in preview mode, append ?preview=true
    let modifiedTo = to;
    if (isPreview) {
        modifiedTo += modifiedTo.includes('?') ? '&preview=true' : '?preview=true';
    }

    return <Link ref={ref} to={modifiedTo} {...props}>{children}</Link>;
});

export default AppLink;
