import React from 'react';

const Button = ({ text, bgColor, textColor, border = true, onClick, style }) => {

    if (style === 'darkLava') {
        return (
            <div
                onClick={onClick}
                style={{
                    borderRadius: '100px',
                    backgroundColor: 'rgba(79, 74, 64, 1)',
                    color: '#D7DECF',
                    fontFamily: 'AreaNormal',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    letterSpacing: '1.2px',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    width: 'fit-content',
                    padding: '15px 35px',
                    whiteSpace: 'nowrap',
                }}
                onMouseEnter={(e) => {
                    e.target.style.backgroundColor = 'rgba(79, 74, 64, 0.7)';
                    e.target.style.color = 'white'; // change text color to white on hover
                }}
                onMouseLeave={(e) => {
                    e.target.style.backgroundColor = 'rgba(79, 74, 64, 1)' // reset the background color
                    e.target.style.color = '#D7DECF)'; // reset the text color
                }}
            >
                {text}
            </div>
        );
    }

    return (
        <div
            onClick={onClick}
            style={{
                backgroundColor: bgColor,
                color: textColor,
                cursor: 'pointer',
                width: 'fit-content',
                fontSize: '0.8em',
                fontWeight: 700,
                borderRadius: '50px',
                textAlign: 'center',
                padding: '15px 35px',
                transition: 'all 0.3s ease',
                border: border ? '1.5px solid rgba(205, 198, 182, 1)' : 'none',
                color: 'rgba(205, 198, 182, 1)',
                letterSpacing: '0.1em',
                lineHeight: '1.5em',
                fontFamily: 'AreaNormal',
                fontWeight: 700,
                textTransform: 'uppercase',
            }}
            onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'rgba(205, 198, 182, 1)';
                e.target.style.color = 'white'; // change text color to white on hover
            }}
            onMouseLeave={(e) => {
                e.target.style.backgroundColor = bgColor; // reset the background color
                e.target.style.color = textColor; // reset the text color
            }}
        >
            {text}
        </div>
    );
}

export default Button;
