import React from 'react';

const Alpha = (props) => {
    return (
        <div style={{
            color: '#4F4A40',
            fontFamily: 'AreaNormal',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '100%'
        }}>
            {props.children}
        </div>
    );
}

export default Alpha;
