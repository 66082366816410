import React, { Fragment } from "react";
import escapeHTML from "escape-html";
import { Text } from "slate";

const Renderer = ({ content }) => {
    const serialize = (children) => {
        return children.map((node, i) => {
            if (Text.isText(node)) {
                let text = (
                    <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />
                );

                if (node.bold) {
                    text = <strong key={i}>{text}</strong>;
                }

                if (node.code) {
                    text = <code key={i}>{text}</code>;
                }

                if (node.italic) {
                    text = <em key={i}>{text}</em>;
                }

                // Handle other leaf types here...

                return <Fragment key={i}>{text}</Fragment>;
            }

            if (!node) {
                return null;
            }

            switch (node.type) {
                case "h1":
                    return <h1 key={i}>{serialize(node.children)}</h1>;
                // Iterate through all headings here...
                case "h6":
                    return <h6 key={i}>{serialize(node.children)}</h6>;
                case "blockquote":
                    return <blockquote key={i}>{serialize(node.children)}</blockquote>;
                case "ul":
                    return <ul key={i}>{serialize(node.children)}</ul>;
                case "ol":
                    return <ol key={i}>{serialize(node.children)}</ol>;
                case "li":
                    return <li key={i}>{serialize(node.children)}</li>;
                case "link":
                    return (
                        <a
                            style={{
                                color: '#4F643F',
                                fontFamily: 'AreaNormal',
                                fontSize: '12px',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '100%', // 24px
                            }}
                            href={escapeHTML(node.url)} key={i}>
                            {serialize(node.children)}
                        </a>
                    );

                default:
                    return <div key={i}>{serialize(node.children)}</div>;
            }
        });
    };

    return <>{serialize(content)}</>;
};

export default Renderer;