import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import './fonts.css';
import Page from './Page';
import Navbar from './Navbar';
import FeaturedArticles from './FeaturedArticles';
import Article from './Article';
import Footer from './Footer';
import About from './About';
import queryString from 'query-string';
import LoginScreen from './LoginScreen';
import { usePreviewAuthentication } from './api/apiService';
import Hotjar from '@hotjar/browser';

const siteId = 3656184;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const defaultBreakpoints = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1600,
};

const BreakpointContext = createContext();

const useBreakpoint = (breakpoints = defaultBreakpoints, containerWidth = null) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState('');

  const calculateCurrentBreakpoint = () => {
    const width = containerWidth !== null ? containerWidth : window.innerWidth;
    const matchingBreakpoints = Object.entries(breakpoints).filter(
      ([, breakpointWidth]) => width >= breakpointWidth
    );

    if (matchingBreakpoints.length) {
      const [newBreakpoint] = matchingBreakpoints.slice(-1)[0];
      setCurrentBreakpoint(newBreakpoint);
    } else {
      setCurrentBreakpoint('unknown');
    }
  };

  useEffect(() => {
    calculateCurrentBreakpoint();

    const handleResize = () => {
      calculateCurrentBreakpoint();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerWidth]);

  return {
    isMobile: currentBreakpoint === 'xs' || currentBreakpoint === 'sm',
    isTablet: currentBreakpoint === 'md',
    isDesktop: currentBreakpoint === 'lg' || currentBreakpoint === 'xl',
  };
};

function ThemedNavbar({ onNavbarHeight }) {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isArticlePage = location.pathname.includes('articles');
  const isAboutPage = location.pathname.includes('about');
  const isStoriesPage = location.pathname.includes('stories');
  let navbarTheme = isArticlePage || isStoriesPage ? 'light' : 'dark';

  if (isScrolled) {
    navbarTheme = 'light';
  }

  if (isAboutPage && !isScrolled) {
    navbarTheme = 'transparentGreen';
  }

  return <Navbar theme={navbarTheme} onNavbarHeight={onNavbarHeight} />;
}

function useScrollToTopOnChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

function ScrollToTop() {
  useScrollToTopOnChange();
  return null;
}

const routesArray = [
  { path: "/:slug", element: <Page /> },
  { path: "/stories/:slug", element: <Article /> },
  { path: "/about", element: <About /> },
  { path: "/", element: <Page slug="home" /> }
];

function App() {
  const breakpoint = useBreakpoint();
  const [navbarHeight, setNavbarHeight] = useState(0);

  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'));
  const [exp, setExp] = useState(localStorage.getItem('exp'));
  const { isPreview } = usePreviewAuthentication();

  const onSuccessfulLogin = () => {
    setJwtToken(localStorage.getItem('jwt_token'));
    setExp(localStorage.getItem('exp'));
  };

  const isTokenExpired = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= exp;
  };

  useEffect(() => {
    if (isTokenExpired()) {
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('exp');
      setJwtToken(null);
      setExp(null);
    }
  }, [exp]);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <ThemedNavbar onNavbarHeight={setNavbarHeight} />
          {(isPreview && (!jwtToken || isTokenExpired())) ? (
            <LoginScreen onSuccessfulLogin={onSuccessfulLogin} />
          ) : (
            <Routes>
              {routesArray.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          )}
        </Router>
      </div>
    </BreakpointContext.Provider>
  );
}

export default App;
export const useBreakpointContext = () => useContext(BreakpointContext);